import Ls from "@/core/services/jwt.service.js";
export default {
  canAccess(permission) {
    let permissions = Ls.getDataPermission().split(",");
    for (let index = 0; index < permissions.length; index++) {
      if (
        permissions[index].trim() === permission.trim() ||
        permissions[index].trim() === "super-admin"
      ) {
        return true;
      }
    }
    return false;
  },

  getAllStrPos(searchStr, str, caseSensitive) {
    let searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
      return [];
    }
    let startIndex = 0,
      index,
      indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  },

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },

  isValueInvalid(value) {
    if (value === undefined || value === null) {
      return true;
    }
    if (typeof value === "object") {
      if (Object.keys(value).length === 0) {
        return true;
      }
    }
    if (typeof value === "string") {
      if (value.trim() === "") {
        return true;
      }
    }
    return false;
  },

  isPositiveInteger(str) {
    if (typeof str !== 'string') {
      str = str.toString();
    }

    const num = Number(str);

    if (Number.isInteger(num) && num > 0) {
      return true;
    }

    return false;
  },

  decodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  },

  encodeHTMLEntities(text) {
    let textArea = document.createElement('textarea');
    textArea.innerText = text;
    return textArea.innerHTML;
  },

  getExtensionByFile(file) {
    return file.name.split('.').pop();
  },

  uniqueArray(arr) {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
  },

  htmlToString(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  },

  getLabelByLangCode(lang = '') {
    let label = "";
    switch (lang) {
      case "vi":
        label = "Tiếng Việt";
        break;
      case "en":
        label = "Tiếng Anh";
        break;
      case "th":
        // label = "ไทย";
        label = "Tiếng Thái";
        break;
      default:
        label = "";
        break;
    }
    return label;
  },

  getAudioDurationFromFile(file) {
    return new Promise((resolve, reject) => {
      const audio = new Audio(URL.createObjectURL(file));
      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration.toFixed(2));
      });
      audio.addEventListener('error', (e) => {
        reject(e);
      });
    });
  },

  getAudioDurationFromBase64OrUrl(source) {
    return new Promise((resolve, reject) => {
      const audio = new Audio(source);
      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration.toFixed(2));
      });
      audio.addEventListener('error', (e) => {
        reject(e);
      });
    });
  }

};
