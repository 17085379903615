import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store/index.js";
import axios from "axios";
import Ls from "@/core/services/jwt.service.js";
import Toasted from "vue-toasted";
import utils from "./helpers/utilities";
import ListConversation from "./plugins/prepChat/ListConversation.js";
import VueChatScroll from "vue-chat-scroll";
//import ApiService from "./core/services/api.service";
//import MockService from "./core/mock/mock.service";
//import { VERIFY_AUTH } from "./core/services/store/auth.module";
//import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

import "vuetify/dist/vuetify.min.css";
import ckeditor from "@ckeditor/ckeditor5-vue2";
import VueScrollTo from "vue-scrollto";

Vue.prototype.$utils = utils;
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

import VueYoutube from "vue-youtube";

Vue.use(VueYoutube);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

Vue.use(DatePicker);

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

Vue.component("multiselect", Multiselect);

Vue.filter("getTag", (rowTag) => {
  const tag = rowTag.split("-");
  return tag[3];
});

import excel from "vue-excel-export";
Vue.use(excel);

import vue_shortkey from "vue-shortkey";
Vue.use(vue_shortkey);

window.axios = axios;
window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// window.axios.defaults.baseURL = 'http://prep-be.example/api/';
// window.axios.defaults.baseURL = 'https://prep.vn.test/api/';
// window.axios.defaults.baseURL = 'https://vng-dev-api.prep.vn/api/';
// window.axios.defaults.baseURL = 'https://api.prep.vn/api/';
// window.axios.defaults.headers.common = {
//   'X-Requested-With': 'XMLHttpRequest'
// }
window.axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const AUTH_TOKEN = Ls.getToken();
    if (AUTH_TOKEN) {
      config.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

window.axios.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    Ls.destroyToken();
    router.push("/login");
  }
  return Promise.reject(error);
});
// API service init
//ApiService.init();

// Remove this to disable mock API
//MockService.init();

// router.beforeEach((to, from, next) => {
//   // Ensure we checked auth before each page load.
//   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

//   // reset config to initial state
//   store.dispatch(RESET_LAYOUT_CONFIG);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });
import * as filters from "./filters";

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(ckeditor);
Vue.use(VueScrollTo);
Vue.use(Toasted);
Vue.use(VueChatScroll);
Vue.use(ListConversation);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
